// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_AQSBI{display:flex;flex-direction:column}.filters_8pynn{align-items:center;display:flex;justify-content:space-between;padding-right:1rem}.row_ppxPe{display:flex;height:100%;width:100%}.row_ppxPe:not(:last-child){margin-bottom:1px}.row_ppxPe.alt_Exgjl{border:none}.cell_3h-Cc{display:flex;height:100%;width:100%}.cell_3h-Cc:not(:last-child){margin-right:1px}.cell_3h-Cc.alt_Exgjl{border:none}.picture_QB7l2{margin-right:.5rem}.pagination_d0JdR{display:flex;justify-content:center;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_AQSBI",
	"filters": "filters_8pynn",
	"row": "row_ppxPe",
	"alt": "alt_Exgjl",
	"cell": "cell_3h-Cc",
	"picture": "picture_QB7l2",
	"pagination": "pagination_d0JdR"
};
module.exports = ___CSS_LOADER_EXPORT___;

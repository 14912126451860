
import Overlay from "~/components/Chrome/components/Overlay/ChromeOverlay.vue";

export default {
  components: { Overlay },

  methods: {
    detailsClicked() {
      this.$amplitude.lokalebasenNoAccessDetails();
    },

    upgradeClicked() {
      this.$amplitude.lokalebasenNoAccessUpgrade();
    },
  },
};
